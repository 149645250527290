let SellProductStepPrice = {
    init: function () {
        const isPrivateSell = window.IS_PRIVATE_SALE;
        const highestBid = parseFloat($('#highest-bid').text());
        const oneWeekInMs = 604800000;
        const twoWeekInMs = 1209600000;
        const threeWeekInMs = 1814400000;
        const durationSelector = document.querySelector('.duration-selector');
        document.querySelectorAll('.step-link').forEach((link) => {
            link.addEventListener('click', (e) => {
                document.querySelector('#policyInput').removeAttribute('required');
                document.querySelector('#termInput').removeAttribute('required');
                durationSelector.removeAttribute('required');
                document.querySelector('input[name="submit"]').value = 'nav';
                let button = document.querySelector('button[type="submit"]');
                button.value = 'nav';
                button.form.step.value = e.currentTarget.dataset.step;
                button.click();
            })
        })
        durationSelector.addEventListener('change', ((event) => {
            let durationDate = 0;
            let selectedValue = event.target.value;
            if (selectedValue === '1') {
                durationDate = new Date().getTime() + oneWeekInMs;
            } else if (selectedValue === '2') {
                durationDate = new Date().getTime() + twoWeekInMs;
            } else if (selectedValue === '3') {
                durationDate = new Date().getTime() + threeWeekInMs;
            }
            const durationText = document.querySelector('.duration-text');
            durationText.innerHTML = `Your offer is valid till ${new Date(durationDate).toLocaleString('de-DE').split(',')[0]}`
        }))
        let timer;
        let resultMessage = $('#result-message'); // Select the result message element
        let inputAsk = $('#askPriceInput');

        // Add an event listener for the input event
        const totalPayoutSpan = $('.total-payout');
        const shippingFeeSpan = $('.shipping-fee');
        const serviceFeeSpan = $('.service-fee');
        const listPhone = $('#list-phone');
        const sellPhone = $('#sell-phone');
        let newAsk = 0;

        function validateAmount() {
            const buyNowInput = $('#buyNowInput');
            let fees = getFee(newAsk, isPrivateSell, 'seller');

            totalPayoutSpan.html(fees.total + '€');
            shippingFeeSpan.html(fees.shippingFee + '€');
            serviceFeeSpan.html(fees.serviceFee + '€');
            if (newAsk < highestBid && !isPrivateSell) {
                inputAsk.val(highestBid);
                newAsk = highestBid;
                buyNowInput.val(highestBid);
                inputAsk.val('');
                durationSelector.classList.add('d-none')
                let fees = getFee(highestBid, isPrivateSell, 'seller');

                totalPayoutSpan.html(fees.total + '€');
                shippingFeeSpan.html(fees.shippingFee + '€');
                serviceFeeSpan.html(fees.serviceFee + '€');
            }

            let message = '';
            let formData = inputAsk.closest('form').serialize();
            const lowestAsk = parseFloat($('#lowest-ask').text());
            sellPhone.addClass('d-none');
            listPhone.removeClass('d-none');
            resultMessage.text('');

            let requestData = {};
            let formArray = formData.split('&');

            for (let i = 0; i < formArray.length; i++) {
                let pair = formArray[i].split('=');
                requestData[pair[0]] = decodeURIComponent(pair[1]); // Use decodeURIComponent to handle URL encoding
            }

            const additionalParams = {
                product_id: window.PRODUCT_ID,
                price: newAsk
            };

            const requestFormData = { ...requestData, ...additionalParams, ...window.STEPPER_DATA };

            if (newAsk && newAsk != '' && !isPrivateSell) {
                $.ajax({
                    url: '/match-offer',
                    method: 'POST', // Change the HTTP method as needed
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-TOKEN': requestData._token, // Include the CSRF token in the headers
                    },
                    data: JSON.stringify(requestFormData),
                    success: function (response) {

                        // // Make an API call to check if the bid is higher than the highest bid and meets the lowest ask

                        if (response && response.success) {
                            if (response.offer && response.offer != null) {
                                $(".tab-2").click();
                                sellPhone.removeClass('d-none');
                                listPhone.addClass('d-none');
                                let url = 'user/offers/accept/' + response.offer.id
                                sellPhone.attr('href', url);
                                const newHighestBid = response.offer.price
                                document.querySelector('#highest-bid').textContent = newHighestBid;
                                inputAsk.val(newHighestBid);
                                newAsk = newHighestBid;
                                buyNowInput.val(newHighestBid);
                                inputAsk.val('');
                                durationSelector.classList.add('d-none');
                                durationSelector.removeAttribute('required');
                                let fees = getFee(newHighestBid, isPrivateSell, 'seller');

                                totalPayoutSpan.html(fees.total + '€');
                                shippingFeeSpan.html(fees.shippingFee + '€');
                                serviceFeeSpan.html(fees.serviceFee + '€');
                            }
                        }
                    },
                    error: function (error) {
                        console.log('error', error);
                        resultMessage.text("Please login to place a bid.");
                    }
                });
            }

        }

        const listButton = document.querySelector('#list-phone');
        if (!isPrivateSell) {
            listButton.addEventListener('click', ((e) => {
                e.preventDefault();
                let formData = inputAsk.closest('form').serialize();
                // Create an object to store the data
                let requestData = {};
                // Split the serialized form data into an array of key-value pairs
                let formArray = formData.split('&');

                // Iterate through the key-value pairs and populate the requestData object
                for (let i = 0; i < formArray.length; i++) {
                    let pair = formArray[i].split('=');
                    requestData[pair[0]] = decodeURIComponent(pair[1]); // Use decodeURIComponent to handle URL encoding
                }

                const additionalParams = {
                    product_id: window.PRODUCT_ID,
                    price: newAsk
                };

                const requestFormData = { ...requestData, ...additionalParams, ...window.STEPPER_DATA };
                $.ajax({
                    url: '/match-offer',
                    method: 'POST', // Change the HTTP method as needed
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-TOKEN': requestData._token, // Include the CSRF token in the headers
                    },
                    data: JSON.stringify(requestFormData),
                    success: function (response) {
                        // // Make an API call to check if the bid is higher than the highest bid and meets the lowest ask
                        if (response && response.success) {
                            if (response.offer && response.offer != null) {
                                Swal.fire({
                                    text: "We found a suitable bid with a price " + response.offer.price,
                                    icon: "success",
                                    buttonsStyling: false,
                                    confirmButtonText: "Ok, got it!",
                                    customClass: {
                                        confirmButton: "btn btn-primary"
                                    }
                                }).then(function (result) {
                                    $.get('user/offers/accept/' + response.offer.id, function () {
                                        window.location.href = '/user/sells/pending'
                                    })
                                });
                            }
                        } else {
                            let form = document.forms["stepperForm"];
                            let inputSubmit = document.createElement("INPUT");
                            inputSubmit.setAttribute("value", "next");
                            inputSubmit.setAttribute("type", "hidden");
                            inputSubmit.setAttribute("name", "submit");
                            form.appendChild(inputSubmit);
                            form.requestSubmit();
                        }
                    },
                    error: function (error) {
                        console.log('error', error);
                        resultMessage.text("Please login to place a bid.");
                    }
                });
            }));
        }


        inputAsk.on('input', function (e) {
            newAsk = e.target.value.replace(/[^0-9]+/g, '');
            $(this).val(newAsk);
            clearTimeout(timer);
            timer = setTimeout(validateAmount, 1000);
        });
    },
};

$().ready(() => {
    if (document.querySelector('#sell-product-step-price')) {
        window.SellProductStepPrice = SellProductStepPrice;
        window.SellProductStepPrice.init();
    }
});